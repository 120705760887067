export type CountryCode = 'US' | 'SV' | 'GT' | 'HN' | 'CR' | 'PA';

export interface Country {
  paiCodigo: CountryCode;
  paiCountryName: string;
  paiMostrarEvento: boolean;
  paiSimboloMno: string;
  paiPhoneCode: string;
  paiMostrarReporte: boolean;
  paiCodigoPostalCapital: string;
}

export type NavCountry = {
  code: CountryCode;
  flag: string;
};

export const countries: NavCountry[] = [
  {
    code: 'US',
    flag: '/countries-icons/usa.svg',
  },
  {
    code: 'SV',
    flag: '/countries-icons/esa.svg',
  },
  {
    code: 'GT',
    flag: '/countries-icons/gt.svg',
  },
  {
    code: 'HN',
    flag: '/countries-icons/ht.svg',
  },
  {
    code: 'PA',
    flag: '/countries-icons/pa.svg',
  },
];
