'use client';

import { setCountryCookie } from '@/actions/cookies/set-country-cookie.action';
import { useScopedI18n } from '@/lib/i18n/client';
import { CountryCode, countries } from '@/types/country-codes';
import Link from 'next/link';

import { Button } from '../ui/button';
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from '../ui/sheet';
import { LocaleSelector } from './locale-selector';

interface Props {
  countryEnv: CountryCode;
  countryCode: CountryCode;
  availableCountries: CountryCode[];
}

export const MobileCountrySelector = ({
  countryEnv,
  countryCode,
  availableCountries,
}: Props) => {
  const t = useScopedI18n('navbar');

  const currentCountry =
    countryEnv === 'US'
      ? countries[0]
      : countries.find((country) => country.code === countryCode) ||
        countries[1];

  const filteredCountries = countries.filter(
    (country) => country.code !== currentCountry.code,
  );

  return (
    <Sheet>
      <SheetTrigger asChild>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={currentCountry.flag}
          alt={currentCountry.code}
          width={30}
          height={30}
        />
      </SheetTrigger>
      <SheetContent side='right' className='border-[#27272a]/60'>
        <SheetTitle>{t('language')}</SheetTitle>

        <div className='mt-6 flex justify-center'>
          <LocaleSelector />
        </div>

        <SheetTitle className='mt-10'>{t('country')}</SheetTitle>

        <ul className='mt-6 space-y-4'>
          {filteredCountries.map((c) => {
            if (!availableCountries.includes(c.code)) {
              return null;
            }

            return (
              <li key={c.code}>
                {/* <Button
                variant='ghost'
                className='flex items-center justify-start space-x-2'
                asChild
              >
                <Link
                  href={
                    countryEnv === 'US'
                      ? 'https://www.smartticket.fun/index.aspx'
                      : c.code === 'US'
                        ? 'https://www.smartticketusa.com/es'
                        : `/?country=${c.code}`
                  }
                >
                  <img src={c.flag} alt={c.code} width={30} height={30} />
                  <span>
                    {t(c.code)}{' '}
                    {c.code === currentCountry.code ? t('selected') : ''}
                  </span>
                </Link>
              </Button> */}

                {countryEnv === 'SV' && c.code !== 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full items-center justify-start space-x-2'
                    onClick={async () => {
                      await setCountryCookie(c.code);
                    }}
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={c.flag} alt={c.code} width={30} height={30} />

                    <span className='pl-2'>
                      {t(c.code)}
                      {c.code === currentCountry.code ? '(Selected)' : ''}
                    </span>
                  </Button>
                )}

                {countryEnv === 'SV' && c.code === 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full items-center justify-start space-x-2'
                    asChild
                  >
                    <Link href='https://www.smartticketusa.com/en'>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={c.flag} alt={c.code} width={30} height={30} />

                      <span className='pl-2'>
                        {t(c.code)}
                        {c.code === currentCountry.code ? '(Selected)' : ''}
                      </span>
                    </Link>
                  </Button>
                )}

                {countryEnv === 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full items-center justify-start space-x-2'
                    asChild
                  >
                    <Link href='https://www.smartticket.fun/index.aspx'>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={c.flag} alt={c.code} width={30} height={30} />

                      <span className='pl-2'>
                        {t(c.code)}
                        {c.code === currentCountry.code ? '(Selected)' : ''}
                      </span>
                    </Link>
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
      </SheetContent>
    </Sheet>
  );
};
