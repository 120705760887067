'use client';

import { useState } from 'react';

import { setCountryCookie } from '@/actions/cookies/set-country-cookie.action';
import { useScopedI18n } from '@/lib/i18n/client';
import { CountryCode, countries } from '@/types/country-codes';
import { ChevronDown } from 'lucide-react';
import Link from 'next/link';

import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

interface Props {
  countryEnv: CountryCode;
  countryCode: CountryCode;
  availableCountries: CountryCode[];
}

export const CountrySelector = ({
  countryEnv,
  countryCode,
  availableCountries,
}: Props) => {
  const t = useScopedI18n('navbar');

  const [open, setOpen] = useState(false);

  const currentCountry =
    countryEnv === 'US'
      ? countries[0]
      : countries.find((country) => country.code === countryCode) ||
        countries[1];

  const filteredCountries = countries.filter(
    (country) => country.code !== currentCountry.code,
  );

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <div className='flex items-center gap-x-2'>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={currentCountry.flag}
            alt={currentCountry.code}
            width={30}
            height={30}
          />

          <span className='lg:hidden'>
            {t(currentCountry.code)}
            {currentCountry.code === 'US' ? '(Selected)' : ''}
          </span>

          <ChevronDown size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='rounded-lg border-[#27272a]/60 bg-black p-0'>
        {filteredCountries.map((c) => {
          if (!availableCountries.includes(c.code)) {
            return null;
          }

          return (
            <DropdownMenuItem key={c.code} asChild>
              <>
                {countryEnv === 'SV' && c.code !== 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full cursor-pointer items-center justify-start py-6 text-white'
                    onClick={async () => {
                      await setCountryCookie(c.code);
                      setOpen(false);
                    }}
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={c.flag} alt={c.code} width={30} height={30} />

                    <span className='pl-2'>
                      {t(c.code)}
                      {c.code === currentCountry.code ? '(Selected)' : ''}
                    </span>
                  </Button>
                )}

                {countryEnv === 'SV' && c.code === 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full cursor-pointer items-center justify-start py-6 text-white'
                    asChild
                  >
                    <Link href='https://www.smartticketusa.com/en'>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={c.flag} alt={c.code} width={30} height={30} />

                      <span className='pl-2'>
                        {t(c.code)}
                        {c.code === currentCountry.code ? '(Selected)' : ''}
                      </span>
                    </Link>
                  </Button>
                )}

                {countryEnv === 'US' && (
                  <Button
                    variant='ghost'
                    className='flex w-full cursor-pointer items-center justify-start py-6 text-white'
                    asChild
                  >
                    <Link href='https://www.smartticket.fun/es'>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={c.flag} alt={c.code} width={30} height={30} />

                      <span className='pl-2'>
                        {t(c.code)}
                        {c.code === currentCountry.code ? '(Selected)' : ''}
                      </span>
                    </Link>
                  </Button>
                )}
              </>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
